import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CallSummaryComponent } from '../call-summary/call-summary.component';
import { IonModal } from '@ionic/angular';

@Component({
  selector: 'app-call-form',
  templateUrl: 'call-form.component.html',
  styleUrls: ['./call-form.component.scss'],
})
export class CallFormComponent {
  name: string;
  rForm: FormGroup;
  @ViewChild(IonModal) modal: IonModal;

  constructor(
      private modalCtrl: ModalController,
      private _formBuilder: FormBuilder,
      ) {}


  ngOnInit() {
    this.rForm = this._formBuilder.group({
        rate_1: [''],
        rate_2: [''],
        rate_3: [''],
      });
  }

  // confirm() {
  //   return this.modalCtrl.dismiss(this.name, 'confirm');
  // }


  async openCallSummary() {
    const modal = await this.modalCtrl.create({
      component: CallSummaryComponent,
      cssClass: 'modal-fullscreen'
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {

    }
  }

  onRateChange(e){
    console.log(e);
    console.log("HIER");
  }

  submitForm(formDirective: FormGroupDirective) {
    this.openCallSummary();
    return this.modalCtrl.dismiss();
  }
}