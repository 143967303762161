import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { takeUntil } from 'rxjs/operators';
import { User } from '../models/user.model';
import { UserService } from '../services/user.service';
import { ToastController } from '@ionic/angular';

export interface AuthData {
  username?: string;
  email?: string;
  firstname?: string;
  lastname?: string;
  gender?: string;
  password: string;
  role?: string;
  craft?: string[];
  experience?: string;
  phoneNumber?: string;
  mobileNumber?: string;
  birthday?: Date;
  availability?: string;
  resetToken?: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  private isAuthenticated = false;
  private user: User;
  private token: string;
  private tokenTimer: any;
  private id: any;
  private authStatusListener = new Subject<boolean>();
  private restServerUrl = '';
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private introductionStatus;
  private storageSub= new Subject<boolean>();
  constructor(
    private router: Router,
    private location: Location,
    private http: HttpClient,
    private __zone: NgZone,
    private userService: UserService,
    private toastController: ToastController
  ) {
    let protocol = environment.ssl ? 'https://' : 'http://';
    this.restServerUrl =
      protocol + environment.restServer + ':' + environment.restServerPort;
  }

  getUserRole() {
    if (!this.user) {
      return;
    }
    return this.user.role;
  }

  getUsername() {
    if (this.user) {
      return this.user['username'];
    } else {
      return;
    }
  }

  getFirstname() {
    if (this.user) {
      return this.user['firstname'];
    } else {
      return;
    }
  }

  getLastname() {
    if (this.user) {
      return this.user['lastname'];
    } else {
      return;
    }
  }

  getFullname() {
    if (this.user) {
      return this.user['firstname'] + ' ' + this.user['lastname'];
    } else {
      return;
    }
  }

  getPasswordResetRequestStatus() {
    if (this.user) {
      return this.user['passwordResetRequest'];
    } else {
      return;
    }
  }

  setPasswordResetRequestStatus(status) {
    this.user['passwordResetRequest'] = status;
    localStorage.setItem('user', JSON.stringify(this.user));
  }

  getToken() {
    return this.token;
  }

  // Check if token is still valid
  validateToken() {
    if (this.isAuthenticated) {
      this.http
        .get<{ token: string; user: any }>(
          this.restServerUrl + '/api/user/validateToken'
        )
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          response => {
            console.log(response);
            //this.authStatusListener.next(true);
          },
          error => {
            this.logout();
            this.__zone.run(() => {
              // this.snackBar.open("Sie haben sich an einem anderen Gerät angemeldet. Daher wurden Sie automatisch abgemeldet.", "",{
              // 	panelClass: 'snack-error',
              // 	duration: 3000,
              // 	verticalPosition: 'bottom',
              // 	horizontalPosition: 'right'
              // });
            });
          }
        );
    }
  }

  getCurrentID() {
		if (this.user) {
			return this.user['_id'];
		} else {
			return;
		}
  }

	getType() {
		if (this.user) {
			return this.user['role'];
		} else {
			return;
		}
	}

  getIsAuth() {
    return this.isAuthenticated;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  setUser(user, token, rememberMe) {
    if (token) {
      this.saveAuthData(token, user, rememberMe); // Save Auth Data to localStorage
      this.userService.setUser = user;
      this.isAuthenticated = true;
      this.authStatusListener.next(true);
    }
  }

  private setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration);
  }

  // Save Auth Data in Browsers localStorage
  saveAuthData(token: string, user: any, rememberMe: boolean) {
    this.user = user;
		if (rememberMe) {
			localStorage.setItem("token", token);
			localStorage.setItem("user", JSON.stringify(user));
		} else {
			sessionStorage.setItem("token", token);
			sessionStorage.setItem("user", JSON.stringify(user));
		}
    // localStorage.setItem("expiration", expirationDate.toISOString()); // localStorage can only save Strings
  }

  // Clear Auth Data from Browsers localStorage
  private clearAuthData() {
    localStorage.removeItem("token");
		sessionStorage.removeItem("token");
		localStorage.removeItem("user");
		sessionStorage.removeItem("user");
  }

  // Get localStorage Auth Data
  private getAuthData() {
    const token = localStorage.getItem("token") || sessionStorage.getItem("token")
		// const expirationDate = localStorage.getItem("expiration");
		const user = JSON.parse(localStorage.getItem("user") || sessionStorage.getItem("user")); 
    if (!token) {
      // if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      // expirationDate: new Date(expirationDate),
      user: user
    };
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    let currentLocation = this.location.path();
    if (!authInformation) {
      if (currentLocation.includes('/reset-password')) {
      } else {
        this.router.navigate(['/login']);
      }
      return;
    }
    // const now = new Date();
    // const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    // console.log("expiresIn: " + expiresIn + " Milliseconds")
    // if (expiresIn > 0) {
    this.token = authInformation.token;
    this.user = authInformation.user;
    this.isAuthenticated = true;
    // this.setAuthTimer(expiresIn);
    this.authStatusListener.next(true);
    // Go to dashboard if user is already logged in
    //if (currentLocation.includes('/login')) {
    	this.router.navigate(["/dashboard"]);
    //}
    // Check for valid token
    //this.validateToken();
  }

  setAuthenticatedStatus() {
    this.authStatusListener.next(this.getIsAuth());
  }

  createUser(
    username: string,
    email: string,
    password: string,
    role: string,
    lastname: string,
    firstname: string,
    craft: string
  ) {
    const authData: AuthData = {
      username: username.toLowerCase(),
      password: password,
      email: email,
      role: role,
      lastname: lastname,
      firstname: firstname,
      craft: [craft]
    };
    this.http
      .post<{ token: string; exp: number; user: any }>(
        this.restServerUrl + '/api/u/register',
        authData
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        response => {
          this.authStatusListener.next(true);
          this.router.navigate(['/dashboard']);

          // this.snackBar.open(message, "",{
          //   duration: 3000,
          //   horizontalPosition: 'right'
          // });
        },
        error => {
          if (error.error.message == 'username taken') {
            // this.snackBar.open("Der Benutzername ist schon vergeben.", "",{
            // 	panelClass: 'snack-error',
            // 	duration: 3000,
            // 	horizontalPosition: 'right'
            //   });
          } else {
            this.authStatusListener.next(false);
          }
        }
      );
  }

  // User login
  login(email: string, password: string, rememberMe: boolean) {
    const authData: AuthData = { email: email, password: password };
    this.http
      .post<{ token: string; exp: number; user: any }>(
        this.restServerUrl + '/api/u/login',
        authData
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        response => {
          console.log(rememberMe);
          const user = response.user;
          this.user = user;
          const token = response.token;
          this.token = token;
          const expirationDateUnix = response.exp; // expiration Date as Unix timestamp
          const expirationDate = new Date(expirationDateUnix * 1000); // JavaScript-Time is in Milliseconds
          this.setUser(user, token, rememberMe);
          this.router.navigate(['/dashboard']);
        },
        error => {
          this.authStatusListener.next(false);
          this.__zone.run(() => {
            let errorText = 'Benutzernamen/Passwort fehlerhaft';
            if (error.status == 0) {
              errorText = 'Keine Verbindung zum Server.';
            }
            // show toast with errortext when an error occured
            this.toastController
              .create({
                message: errorText,
                duration: 5000,
                position: 'bottom',
                color: 'danger'
              })
              .then(toast => toast.present());
          });
        }
      );
  }
  
  logout() {
    this.isAuthenticated = false;
    this.authStatusListener.next(this.isAuthenticated);
    this.clearAuthData();
    this.router.navigate(['/login']);
  }

  // User reset password
  requestPassword(email: string) {
    this.http
      .post(this.restServerUrl + '/api/u/resetPassword', { email: email })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        response => {
          this.__zone.run(() => {
            this.router.navigate(['/login']);
            var message = 'Die Änderung des Kennworts wurde beantragt.';
            this.showToastOnSuccess(message);
          });
        },
        error => {
          this.showToastOnError("Diese Email existiert nicht");
          console.error(error);
          return;
  
        }
      );
  }

  // User update password
  resetPassword(resetToken: string, password: string) {
    const authData: AuthData = { resetToken: resetToken, password: password };
    this.http
      .post<{ token: string; exp: number; user: any }>(
        this.restServerUrl + '/api/u/updateRecoveryPassword',
        authData
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        response => {
          const user = response.user;
          this.user = user;
          const token = response.token;
          this.token = token;
          const expirationDateUnix = response.exp; // expiration Date as Unix timestamp
          const expirationDate = new Date(expirationDateUnix * 1000); // JavaScript-Time is in Milliseconds
          this.setUser(user, token, true);
          this.router.navigate(['/dashboard']);
        },
        error => {
          console.error(error);
        }
      );
  }

  async showToastOnError(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'bottom',
      color: 'danger'
    });
    toast.present();
  }

  async showToastOnSuccess(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'bottom',
      color: 'success'
    });
    toast.present();
  }

  watchIntroductionStatus() {
    return this.storageSub.asObservable();
  }

  setIntroductionStatus(introductionStatus) {
    this.introductionStatus = introductionStatus;
    this.storageSub.next(introductionStatus);
  }


  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
