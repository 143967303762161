import { Component, AfterViewInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { SocketIOService } from '../../services/socket.io.service';
import { WebrtcService } from '../../services/webrtc.service';
import { ModalController } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { Subject, Subscription } from 'rxjs';
import { SessionService} from '../../services/session.service'
import { AuthService } from '../../auth/auth.service';
import { takeUntil } from 'rxjs/operators';
export interface Session {};
/**
Step 1: caller creates offer

Step 2: caller sets localDescription

Step 3: caller sends the description to the callee

//------------------------------------------------------//

Step 4: callee receives the offer sets remote description

Step 5: callee creates answer

Step 6: callee sets local description

Step 7: callee send the description to caller

//------------------------------------------------------//

Step 8: caller receives the answer and sets remote description
 */

const n = <any>navigator;

@Component({
    selector: 'app-video-call',
    templateUrl: './video-call.component.html',
    styleUrls: ['./video-call.component.scss']
})
export class VideoCallComponent implements AfterViewInit {
    ICE_SERVERS: RTCIceServer[] = environment.iceServers;
    public peerConnection: any;
    public localStream: MediaStream;
    public remoteStream: MediaStream;
    public myEl: HTMLMediaElement;
    public partnerEl: HTMLMediaElement;
    public offerOptions = {
        offerToReceiveAudio: true,
        offerToReceiveVideo: true
    };
    public loggedUserName;
    public constraints: MediaStreamConstraints = {
        audio: {
            // autoGainControl: false,
            // echoCancellation: true,
            // noiseSuppression: false
            //latency: 0,
        },
        //video: true
        video: {
          width: { 
            //min: 320,
            max: 854
          },
          height: {
            //min: 180,
            max: 480
          },
          // aspectRatio: {
          //   ideal: 1.2
          // },
          facingMode: {
              ideal: "user"
          }
        }
    };
    videoDevices = [];
    activeVideoDevice;
    isCallSummary = false;
    public caller;
    @Input('caller')
    public craftsmanId;
    @Input('craftsmanId')
    public diyId;
    @Input('diyId')
    sessionsId;
    public set setCaller(_caller) {
        console.log('caller')
        this.changeDetector.detectChanges();
        this.caller = _caller;
    }

    public userType;
    userRole;
    @Input("userType")
    public set setUserType(_type) {
        console.log('usertype')
        this.ngAfterViewInit();
        if (_type == 'dialer') {
            setTimeout(() => {
                this.changeDetector.detectChanges();
                this.Call();
            }, 2000);
        }
    }
    id;
    @Output("callback")
    sessionId;
    time: number = 0;
    interval;
    callback: EventEmitter<Object> = new EventEmitter<Object>();
    private _unsubscribeAll: Subject<any>;
    private ReceiveCallSub: Subscription;
    constructor(
        private socketIOService: SocketIOService,
        private webrtcService: WebrtcService,
        private changeDetector: ChangeDetectorRef,
        private modalController: ModalController,
        private sessionService: SessionService,
        private authService: AuthService
    ) {
    }

    ngAfterViewInit() {
        this.userRole = this.authService.getUserRole();
        const isModalOpened = this.modalController.getTop();
        if (isModalOpened) {
            if (this.userType == 'dialer') {
                setTimeout(() => {
                    this.changeDetector.detectChanges();
                    this.Call();
                }, 2000);
            }
        }
        this.getNavigator();
        this.loggedUserName = sessionStorage.getItem("username");
        this.ReceiveCallSub = this.socketIOService
            .ReceiveCallRequest().subscribe(data => {
                this.OnCallRequestReceived(data.data);
                });
        this.OnCallEnded();
        this.OnTimerStart();
    }

    OnTimerStart() {
        this.socketIOService
            .onTimerStart()
            .subscribe(data => {
                if (data) {
                    this.interval = setInterval(() => {
                        this.time++;
                    }, 1000)
                }
            });
    }

    SetConnection() {
        const restartConfig = environment.iceServers;
        //on both side
        this.peerConnection = new RTCPeerConnection();
        this.peerConnection.setConfiguration(restartConfig);

        this.localStream.getTracks().forEach(track => {
            this.peerConnection.addTrack(track, this.localStream);
        });
        //this.peerConnection.addStream(this.localStream);
        this.peerConnection.onicecandidate = e => {
            this.OnIceCandidate(this.peerConnection, e);
        };
        this.peerConnection.ontrack = (event) => {
            // don't set srcObject again if it is already set.
            for (var stream of event.streams) {
                console.log("Remote streams: " + stream.id);
            }
            //getting first stream only 
            //this is only for p2p call
            this.GotRemoteStream(event.streams[0]);
        };
        this.peerConnection.oniceconnectionstatechange = e => {
            this.OnIceStateChange(this.peerConnection, e);
        };
    }

    OnCallRequestReceived(data) {
        console.log("call received");
        if (data.desc) {
            var descrip = new RTCSessionDescription(data.desc);
            if (descrip.type == "offer") {
                this.SetConnection();
                this.OnCallOffer(descrip);
            } else if (descrip.type == "answer") {
                this.OnCallAnswer(descrip);
            } else {
                console.log("Unsupported SDP type!!");
            }
        } else if (data.candidate) {
            var candidate = new RTCIceCandidate(data.candidate);
            this.peerConnection.addIceCandidate(candidate).catch(err => console.log(err));
        }
    }

    Call() {
        this.SetConnection();
        /**Step 1: caller creates offer */
        console.log("STEP 1");
        this.peerConnection.onnegotiationneeded = async () => {
            try {
                this.peerConnection.createOffer(this.offerOptions)
                    .then(
                        this.OnCreateOfferSuccess.bind(this),
                        this.OnCreateSessionDescriptionError.bind(this));
            } catch (err) {
                console.error(err);
            }
        };
    }

    OnCreateOfferSuccess(event) {
        /**Step 2: caller sets localDescription */
        console.log("STEP 2");
        this.peerConnection.setLocalDescription(new RTCSessionDescription(event)).then(
            () => {
                console.log("STEP3");
                /**Step 3: caller sends the description to the callee */
                this.socketIOService.SendCallRequest(this.peerConnection.localDescription, 'desc', this.caller);
                this.ShowSuccess('created offer /nlocal description set /n=>Success');
            },
            this.OnSetSessionDescriptionError.bind(this)
        );
    }

    OnCallOffer(descrip) {
        /**Step 4: callee receives the offer sets remote description */
        console.log("STEP 4");
        this.peerConnection.setRemoteDescription(descrip).then(
            () => {
                this.OnSetRemoteSuccess(this.peerConnection);
            },
            this.OnSetSessionDescriptionError.bind(this)
        );
    }

    OnSetRemoteSuccess(val) {
        /**Step 5: callee creates answer */
        console.log("STEP 5");
        this.peerConnection.createAnswer().then(
            this.OnCreateAnswerSuccess.bind(this),
            this.OnCreateSessionDescriptionError.bind(this)
        );
    }

    OnCreateAnswerSuccess(event) {
        /**Step 6: callee sets local description */
        console.log("STEP 6");
        this.peerConnection.setLocalDescription(new RTCSessionDescription(event)).then(
            () => {
                console.log("STEP7")
                /**Step 7: callee send the description to caller */
                this.socketIOService.SendCallRequest(this.peerConnection.localDescription, 'desc', this.caller);
                this.ShowSuccess("create answer /n=>success");
          
            },
            this.OnSetSessionDescriptionError.bind(this)
        );
    }

    OnCallAnswer(descrip) {
        console.log("STEP 8");
        /**Step 8: caller receives the answer and sets remote description */
        this.peerConnection.setRemoteDescription(new RTCSessionDescription(descrip))
            .then(() => {
                this.ShowSuccess('Succesfully set remote description')
                // create sessions and get id
                let data = {
                    craftsman : this.craftsmanId,
                    diy: this.diyId
                }
                this.sessionService.startSession(data).subscribe(res => {
                    this.sessionId = res["_id"];
                    var caller = this.socketIOService.connectedusers.find(a => a.id == this.caller);
                    // start timer both user
                    this.socketIOService.timerStart(this.loggedUserName, this.caller, caller.username);
                    this.interval = setInterval(() => {
                        this.time++;
                    }, 1000)
                });
         
            }).catch(err => { console.log(err); });
    }

    //get local stream
    async GetLocalStream() {
        await n.mediaDevices.getUserMedia(this.constraints)
                    .then(this.GotLocalStream.bind(this))
                    .catch(this.HandleDeviceError.bind(this));
    }

    async getNavigator() {
        if(typeof n.mediaDevices === 'undefined' || !n.mediaDevices.getUserMedia) {
            alert('This browser does not supports WebRTC getUserMedia API.');
    
            if(!!n.getUserMedia) {
                alert('This browser seems supporting deprecated getUserMedia API.');
            }
        }
        this.GetLocalStream();
    }

    //got local stream
    GotLocalStream(stream) {
        console.log("GetLocalStream")
        this.localStream = stream;
        this.myEl = document.getElementById('local-video') as HTMLVideoElement;
        this.myEl.srcObject = this.localStream;
        this.myEl.controls = false;
        this.myEl.muted = true;
        this.myEl.volume = 0;
        console.log("local stream id => " + this.localStream.id);
        this.activeVideoDevice = this.localStream.getVideoTracks()[0].getSettings().deviceId;
        console.log(this.activeVideoDevice)
        // Get video devices
        this.webrtcService.initializeDevices().then(() => {
            this.videoDevices = this.webrtcService.getVideoDevices();
        });
        //this.SetConnection();
    }

    OnIceCandidate(conn, event) {
        if (event.candidate) {
            // Send the candidate to the remote peer
            //console.log("Send the candidate to the remote peer");
            var candi = new RTCIceCandidate(event.candidate);
            this.socketIOService.SendCallRequest(candi, 'candidate', this.caller);
        } else {
            // All ICE candidates have been sent
            //console.log("All ICE candidates have been sent");
        }
    }

    OnIceStateChange(peerConn, event) {
        if (peerConn) {
            //console.log('ICE state change event: ', event);
        }
    }

    GotRemoteStream(stream) {
        //console.log("got remote stream");
        this.remoteStream = stream;
        this.partnerEl = document.getElementById('remote-video') as HTMLVideoElement;
        this.partnerEl.srcObject = this.remoteStream;
        // var remotevid=document.createElement("video") as HTMLVideoElement;
        // remotevid.srcObject=stream;
        // remotevid.autoplay = true;
        // remotevid.id=stream.id;
        // var lv = document.querySelector('#div-remote-video');
        // lv.appendChild(remotevid);

        // for (var stream of event.streams) {
        //     if (!lv.srcObject)
        //         lv.srcObject = stream;
        //     console.log("Remote streams: " + stream.id);
        // }
    }

    OnCreateSessionDescriptionError(event) {
        //console.log("OnCreateSessionDescriptionError");
        // this.peerConnection.setRemoteDescription(event).then(
        //     () => {
        //         this.OnSetRemoteSuccess(this.peerConnection);
        //     },
        //     this.OnSetSessionDescriptionError.bind(this)
        // );
    }

    ShowSuccess(message) {
        console.log(message);
    }

    OnSetSessionDescriptionError(val) {
        //console.log("error " + val);
    }

    GotDevices(deviceInfos) {
        // Handles being called several times to update labels. Preserve values.
        // const values = this.selectors.map(select => select.value);
        // selectors.forEach(select => {
        //   while (select.firstChild) {
        //     select.removeChild(select.firstChild);
        //   }
        // });
        var flagMic = false;
        var flagSpeaker = false;
        var flagWebCam = false;
        for (let i = 0; i !== deviceInfos.length; ++i) {
            const deviceInfo = deviceInfos[i];
            //console.log(i + 1, deviceInfo.label);
            if (deviceInfo.kind == 'audioinput') {
                flagMic = true;
            }
            if (deviceInfo.kind == 'audiooutput') {
                flagSpeaker = true;
            }
            if (deviceInfo.kind == 'videoinput') {
                flagWebCam = true;
            }
            //   const option = document.createElement('option');
            //   option.value = deviceInfo.deviceId;
            //   if (deviceInfo.kind === 'audioinput') {
            //     option.text = deviceInfo.label || `microphone ${audioInputSelect.length + 1}`;
            //     audioInputSelect.appendChild(option);
            //   } else if (deviceInfo.kind === 'audiooutput') {
            //     option.text = deviceInfo.label || `speaker ${audioOutputSelect.length + 1}`;
            //     audioOutputSelect.appendChild(option);
            //   } else if (deviceInfo.kind === 'videoinput') {
            //     option.text = deviceInfo.label || `camera ${videoSelect.length + 1}`;
            //     videoSelect.appendChild(option);
            //   } else {
            //     console.log('Some other kind of source/device: ', deviceInfo);
            //   }
        }
        var msg = '';
        if (!flagMic) {
            msg += 'microphone ';
        }
        if (!flagSpeaker) {
            msg += 'speaker ';
        }
        if (!flagWebCam) {
            msg += 'webcam ';
        }
        if (msg != '') {
            alert(msg + "not found!");
        }
        // selectors.forEach((select, selectorIndex) => {
        //   if (Array.prototype.slice.call(select.childNodes).some(n => n.value === values[selectorIndex])) {
        //     select.value = values[selectorIndex];
        //   }
        // });
    }

    HandleDeviceError(error) {
        console.log("ERROERRRRRRRR")
        if (error.name == "NotFoundError" || error.name == "DevicesNotFoundError") {
            alert("webcam or mic not connected to your system");
        }
        else if (error.name == "NotReadableError" || error.name == "TrackStartError") {
            alert("webcam or mic already in use by another application");
        } else if (error.name == "OverconstrainedError" || error.name == "ConstraintNotSatisfiedError") {
            alert("webcam or mic not supported!");
        } else if (error.name == "NotAllowedError" || error.name == "PermissionDeniedError") {
            alert("Access denied for accessing webcam or mic!");
        } else if (error.name == "MediaStreamError" || error.name == "TypeError") {
            //empty constraints object
            //alert("Unable to get media!");
            console.log("empty constraints object");
        } else if (error.name == "PermissionDismissedError") {
            alert("Permission is dismissed for access webcam or mic");
        }
        console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
    }

    EndCall() {
        var caller = this.socketIOService.connectedusers.find(a => a.id == this.caller);
        this.socketIOService.EndVideoCall(this.loggedUserName, this.caller, caller.username);
        this.CallBack();
    }


    OnCallEnded() {
        this.socketIOService
            .OnVideoCallEnded()
            .subscribe(data => {
                if (data) {
                    this.socketIOService.GetBusyUsers();
                    this.CallBack();
                }
            });
    }

    CallBack() {
        this.closeModal();
        console.log("CALLBACK FIRED");
        console.log("session ID"  + this.sessionId);
        if (this.userRole == "diy") {
            this.sessionService.endSession(this.sessionId).subscribe(res => {
                console.log(res);
            })
        }
        //clear timer on call end
        clearInterval(this.interval);

        // Doppelter Vorhang deswegen
        // var senders = this.peerConnection.getSenders();
        // senders.forEach(s => {
        //     this.peerConnection.removeTrack(s);
        // });

        // stop both video and audio
        this.localStream.getTracks().forEach((track) => {
            track.stop();
        });
        // stop only audio
        this.localStream.getAudioTracks()[0].stop();
        //stop only audio 
        this.localStream.getVideoTracks()[0].stop();
        //this.peerConnection.close();

        this.peerConnection = new RTCPeerConnection();
        this.callback.emit({ status: "ended" });
    }

    async switchCamera() {
        console.log('switch camera')
        try {
            if (this.localStream) {
                this.localStream.getTracks().forEach(track => {
                    track => track.stop()
                });
            }
            console.log('local stream stopped')
            const currentVideoIndex = this.videoDevices.indexOf(this.activeVideoDevice);
            const nextIndex = (currentVideoIndex + 1) % this.videoDevices.length;
            console.log(this.videoDevices[nextIndex])
            // const updatedConstraints = {
            //     ...this.constraints,
            //     deviceId: {
            //         exact: this.videoDevices[nextIndex]
            //     }
            // };
            const updatedConstraints = {
                audio: {
                },
                video: {
                  width: { 
                    max: 854
                  },
                  height: {
                    max: 480
                  },
                  deviceId: {
                    exact: this.videoDevices[nextIndex]
                  }
                }
            }
            this.activeVideoDevice = this.videoDevices[nextIndex];
            console.log('update stream')
            await n.mediaDevices.getUserMedia(updatedConstraints)
                    .then(this.setNewStream.bind(this))
                    .catch(this.HandleDeviceError.bind(this));
          } catch (e) {
            //alert(e);
            return;
          }
    }

    setNewStream(stream) {
        this.localStream = stream;
        this.myEl.srcObject = this.localStream;
        // this.myEl.controls = false;
        // this.myEl.muted = true;
        this.myEl.volume = 0;
        console.log('new local stream set')

        const tracks = this.localStream.getTracks()
        this.peerConnection.getSenders()
          .forEach(sender => {
            const newTrack = tracks.find(track => track.kind === sender.track.kind)
            sender.replaceTrack(newTrack)
          })
    }

    async closeModal() {
        await this.modalController.dismiss({ status: "ended" });
    }

    ngOnDestroy() {
        this.ReceiveCallSub.unsubscribe();
        //clear timer
        clearInterval(this.interval);
    }
}
