import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Craftsman, User } from '../models/user.model';

// DUMMY
export interface Benutzer {}
export interface Craftsmans {
  _id: string;
  username: string;
  role: string;
}

@Injectable({ providedIn: 'root' })
export class UserService {
  private restServerUrl = '';
  private user: BehaviorSubject<User> = new BehaviorSubject(
    this.getUserFromLocalStorage()
  );
  private selectedUser: BehaviorSubject<User> = new BehaviorSubject(
    this.getUserFromLocalStorage()
  );
  private craftsmen: BehaviorSubject<User[]> = new BehaviorSubject([]);
  private fieldTags: BehaviorSubject<string[]> = new BehaviorSubject([]);
  craftman: Craftsmans[] = [];
  craftmanUpdated = new Subject<Craftsmans[]>();
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  // DUMMY !!!!
  benutzer: Craftsmans[] = [];
  benutzerUpdated = new Subject<Craftsmans[]>();

  constructor(private http: HttpClient) {
    let protocol = environment.ssl ? 'https://' : 'http://';
    this.restServerUrl =
      protocol + environment.restServer + ':' + environment.restServerPort;
  }

  // ---------------------------------------------------------------------------------------------
  // @ Getters & Setters
  // ---------------------------------------------------------------------------------------------

  /**
   * Getter for the user
   */
  public get user$(): Observable<User> {
    return this.user.asObservable();
  }

  /**
   * Setter for user
   */
  public set setUser(user: User) {
    this.user.next(user);
  }

  /**
   * Getter for the selected user
   */
  public get selectedUser$(): Observable<User> {
    return this.selectedUser.asObservable();
  }

  /**
   * Getter for field tag list
   */
  public get fieldTags$(): Observable<string[]> {
    return this.fieldTags.asObservable();
  }

  // ---------------------------------------------------------------------------------------------
  // @ Private methods
  // ---------------------------------------------------------------------------------------------
  private getUserFromLocalStorage(): User {
    return JSON.parse(localStorage.getItem('user'));
  }

  // ---------------------------------------------------------------------------------------------
  // @ Public methods
  // ---------------------------------------------------------------------------------------------

  /**
   * Get the currently logged in user
   * @returns an Observable for the current user
   */
  public getUser(): Observable<User> {
    return this.http
      .get<User>(this.restServerUrl + '/api/u/userById/' + this.user.value._id)
      .pipe(tap(user => this.user.next(user)));
  }

  /**
   * Gets a user by its id.
   * @param id user id as `string`
   * @returns Observable of the User
   */
  getUserbyId(id: string): Observable<User> {
    return this.http
      .get<User>(this.restServerUrl + '/api/u/userById/' + id)
      .pipe(tap(user => this.selectedUser.next(user)));
  }

  public updateCraftsman(craftsman: Craftsman): Observable<User> {
    return this.http
      .put<User>(this.restServerUrl + '/api/u/updateOwnUser', craftsman)
      .pipe(tap(user => this.user.next(user)));
  }

  /**
   * Gets the provided taglist
   * @returns Observable of tags as a string array
   */
  public getFieldTags(): Observable<string[]> {
    return this.http
      .get<string[]>(this.restServerUrl + '/api/u/fieldTags')
      .pipe(tap(fieldTags => this.fieldTags.next(fieldTags)));
  }

  /**
   * Gets all Craftsmen
   * @returns Observable of craftsmen as a User array
   */
  public getCraftsmen(): Observable<User[]> {
    return this.http
      .get<User[]>(this.restServerUrl + '/api/u/craftsmanList')
      .pipe(tap(craftsmen => this.craftsmen.next(craftsmen)));
  }

  /**
   * Gets all Craftsmen by the provided fieldtags
   * @returns Observable of craftsmen as a User array
   */
  public searchCraftsmen(fieldTags: string[]): Observable<User[]> {
    return this.http.post<User[]>(
      this.restServerUrl + '/api/u/searchCraftsmanWithFieldTags',
      {
        fieldTags
      }
    );
  }

  getCraftmanUpdateListener() {
    return this.craftmanUpdated.asObservable();
  }

  /**
   * get list of all craftmans
   */
  getCraftmansList() {
    this.http
      .get<Craftsmans[]>(this.restServerUrl + '/api/u/craftsmanList')
      .pipe(
        map(data => data),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(data => {
        this.craftman = data;
        this.craftmanUpdated.next([...this.craftman]);
      });
  }

  //DUMMY !!!!
  getBenutzerUpdateListener() {
    return this.benutzerUpdated.asObservable();
  }

  /**
   * get list of all benutzer
   */
  getBenutzerList() {
    this.http
      .get<Craftsmans[]>(this.restServerUrl + '/api/u/benutzerList')
      .pipe(
        map(data => data),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(data => {
        this.benutzer = data;
        this.benutzerUpdated.next([...this.benutzer]);
      });
  }
}
