import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './guards/admin.guard';
import { IntroductionGuard } from './guards/introduction.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'request-password',
    loadChildren: () =>
      import('./pages/request-password/request-password.module').then(m => m.RequestPasswordModule)
  },
  {
    path: 'reset-password/:resetToken',
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then(
        m => m.ResetPasswordModule
      )
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      canLoad: [IntroductionGuard] 
  },
  {
    path: 'introduction',
    loadChildren: () =>
      import('./pages/introduction/introduction.module').then(m => m.IntroductionModule)
  },
  {
    path: 'create-question',
    loadChildren: () =>
      import('./pages/create-question/create-question.module').then(
        m => m.CreateQuestionModule
      )
  },
  {
    path: 'craftsman',
    loadChildren: () =>
      import('./pages/craftman/craftman.module').then(m => m.CraftmanModule)
  },
  {
    path: 'craftsman-validation',
    loadChildren: () =>
      import('./pages/craftsman-validation/craftsman-validation.module').then(
        m => m.CraftsmanValidationModule
      )
  },
  {
    path: 'profile',
    children: [
      {
        path: 'edit',
        loadChildren: () =>
          import('./pages/craftsman-edit/craftsman-edit.module').then(
            m => m.CraftsmanEditModule
          )
      },
      {
        path: ':id',
        loadChildren: () =>
          import('./pages/profile/profile.module').then(m => m.ProfileModule)
      }
    ]
  },
  {
    path: 'user-list',
    loadChildren: () =>
      import('./pages/user-list/user-list.module').then(
        m => m.UserListModule
      )
  },

  // Admin routes
  {
    path: 'admin',
    canActivate: [AdminGuard],
    canActivateChild: [AdminGuard],
    children: [
      {
        path: 'questions',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/question-list/question-list.module').then(
                m => m.QuestionListModule
              )
          },
          {
            path: 'edit',
            loadChildren: () =>
              import('./pages/create-question/create-question.module').then(
                m => m.CreateQuestionModule
              )
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
