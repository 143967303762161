import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormGroupDirective } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'call-summary',
  templateUrl: 'call-evaluation.component.html',
})
export class CallEvaluationComponent {
  rForm: FormGroup;

  constructor(
    private modalCtrl: ModalController,
    private _formBuilder: FormBuilder,
    ) {}

  ngOnInit() {
    this.rForm = this._formBuilder.group({
      note: [''],
    });
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm(form: FormGroupDirective) {
    if (form.invalid) {
      return;
    }
    return this.modalCtrl.dismiss(null, 'confirm');
  }

 
}