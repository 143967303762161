import { Component } from '@angular/core';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'call-summary',
  templateUrl: 'call-summary.component.html',
})
export class CallSummaryComponent {

  constructor(private modalCtrl: ModalController) {}

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss(null, 'confirm');
  }
}