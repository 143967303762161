import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface DiySession {
    start:Date;
    _craftsman: any;
    duration:any;
    diyNote: string;
  }
  
  export interface CraftsmanSession {
    start:Date;
    _diy: any;
    duration:any;
    craftsmanNote: string;
  }
  export interface  CraftsmanPerformance{
    thisMonthMinutes: Number,
    thisMonthSessions: Number,
    thisMonthEarnings: Number
  }

  export interface CallSession {}

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private restServerUrl: string;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  diyDashboardDataUpdated = new Subject<DiySession[]>();
  diyDashboardSession: DiySession[] = [];
  craftsmanDashboardDataUpdated = new Subject<CraftsmanSession[]>();
  craftsmanDashboardSession: CraftsmanSession[] = [];
  craftsmanPerformanceUpdated = new Subject<CraftsmanPerformance[]>();
  craftsmanPerformance: CraftsmanPerformance[] = [];
  callSessionUpdated = new Subject<any>();
  callSession: any;
  constructor(private http: HttpClient) {
    let protocol = environment.ssl ? 'https://' : 'http://';
    this.restServerUrl =
      protocol + environment.restServer + ':' + environment.restServerPort;
  }

  
  getDiyDashboardDataUpdateListener() {
    return this.diyDashboardDataUpdated.asObservable();
  } 

  getCraftsmanDashboardDataUpdateListener() {
    return this.craftsmanDashboardDataUpdated.asObservable();
  } 

  
  getCraftsmanPerformanceUpdateListener() {
    return this.craftsmanPerformanceUpdated.asObservable();
  } 

    /**
     * diy data for Dahsboard
    */
  getDiyDashboardData(id?:string) {
    var param = id ? '/'+ id : '';
    this.http.get<DiySession[]>(this.restServerUrl + "/api/s/diyDashboard" + param)
      .pipe(map(data => data), takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        console.log(data)
        this.diyDashboardSession = data;
        this.diyDashboardDataUpdated.next(this.diyDashboardSession);
    });
  }

      /**
     * diy data for Dahsboard
    */
   getCraftsmanDashboardData(id?:string) {
    var param = id ? '/'+ id : '';
    this.http.get<CraftsmanSession[]>(this.restServerUrl + "/api/s/craftsmanDashboard" + param)
      .pipe(map(data => data), takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        console.log(data)
        this.craftsmanDashboardSession = data;
        this.craftsmanDashboardDataUpdated.next(this.craftsmanDashboardSession);
    });
  }

    /**
     * get craftsman performance
    */
   getCraftsmanPerformance(id?:string) {
    var param = id ? '/'+ id : '';
    this.http.get<CraftsmanPerformance[]>(this.restServerUrl + "/api/s/craftsmanPerformance" + param)
      .pipe(map(data => data), takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        console.log(data)
        this.craftsmanPerformance = data;
        this.craftsmanPerformanceUpdated.next(this.craftsmanPerformance);
    });
  }

  
  // startSession(data) {
  //  return this.http
  //     .post(this.restServerUrl + '/api/s/startSession', data)
  //     .pipe(takeUntil(this._unsubscribeAll))
  //     .subscribe(
  //       response => {
  //         console.log(response)
  //         return response["_id"];
  //       },
  //       error => {
  //         console.log(error);
  //       }
  //     );
  // }

  startSessionUpdateListener() {
    return this.callSessionUpdated.asObservable();
  }
  
  startSession(data) {
    console.log(data)
    return this.http.post(this.restServerUrl + '/api/s/startSession', data, 
    { headers: new HttpHeaders().set('Content-Type', 'application/json') })
    .pipe(map(data => {
      return <CallSession>data;
      // console.log(data)
      // this.callSessionUpdated.next(data);
    }));
  }

  endSession(id)  {
		return this.http.put(
			this.restServerUrl + '/api/s/endSession/' + id,
			{ headers: new HttpHeaders().set('Content-Type', 'application/json') })
			.pipe(map(res => {
        return <CallSession>res;
			}));
	}
}
