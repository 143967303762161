import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CallFormComponent } from './call-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicRatingModule } from 'ionic-rating';


@NgModule({
  declarations: [
    CallFormComponent
  ],
  entryComponents: [
    CallFormComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    IonicRatingModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [CallFormComponent]
})
export class CallFormModule {}
