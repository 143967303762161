import { Component, ChangeDetectorRef } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { addIcons } from 'ionicons';
import { SocketIOService } from './services/socket.io.service';
import { AlertController } from '@ionic/angular';
import { VideoCallComponent } from './pages/video-call/video-call.component';
import { ModalController } from '@ionic/angular';
import { AlertService } from './services/alert.service';
import { TouchSequence } from 'selenium-webdriver';
import { CallSummaryComponent } from './pages/call-summary/call-summary.component';
import { CallEvaluationComponent } from './pages/call-evaluation/call-evaluation.component';
import OneSignal from 'onesignal-cordova-plugin';
import { Capacitor } from '@capacitor/core';

export const INTRO_KEY = 'intro-seen';
addIcons({
  material_phone_enabled: 'assets/icons/material_phone_enabled.svg',
  material_phone_disabled: 'assets/icons/material_phone_disabled.svg'
});
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  userIsAuthenticated: boolean = false;
  private _unsubscribeAll: Subject<any>;
  private authListenerSubs: Subscription;
  userType;
  isCraftsman;
  isAvailable: boolean = false;
  userId;
  user;
  seenIntroduction: boolean = false;
  public isChat = false;
  public isVideoCall = false;
  public isAudioCall = false;
  public caller: any;
  public callingInfo = { name: "", content: "", type: "" };
  public liveUserList = [];
  public currentAlert;
  public loggedUserName;
  public isVideoCallAccepted: boolean = false;
  constructor(
    private authService: AuthService, 
    private router: Router,
    private socketIOService: SocketIOService,
    private alertController: AlertController,
    private changeDetector: ChangeDetectorRef,
    private modalController: ModalController,
    private alertService: AlertService
    ) {
    }

  ngOnInit() {
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authService.autoAuthUser();
    this.authListenerSubs = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getCurrentID();
        this.userType = this.authService.getType();

        // check Introduction status from localstorage 
        this.authService.watchIntroductionStatus().subscribe(res => {
          if (res == false) {
            this.seenIntroduction = false; 
          } else if (res == true) {
            this.seenIntroduction = true; 
          }
        })
      
        // One Signal intigration (only for devices)
        if (Capacitor.isNativePlatform()) {
          this.OneSignalInit();
        };
      
        if (this.userType == 'craftsman') {
          this.isCraftsman = true;
        } else {
          this.isCraftsman = false;
        }

        if (this.userIsAuthenticated) {
          this.socketIOService.initSocket();
          // set user in socket array
          this.AddUser(this.userId);
          this.GetLiveUsers();
          this.OnVideoCallRequest();
          this.OnVideoCallAccepted();
          //this.GetBusyUsers();
          this.OnVideoCallRejected(); 
        }

      });
    this.authService.setAuthenticatedStatus();
  }

  // open call summary and after confirm open call evaluation
  async callSummary() {
    const modal = await this.modalController.create({
      component: CallSummaryComponent,
      cssClass: 'modal-fullscreen',
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      this.callEvaluation();
    }
  }

  // call evalaluation modal
  async callEvaluation() {
    const modal = await this.modalController.create({
      component: CallEvaluationComponent,
      cssClass: 'modal-fullscreen',
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
     console.log("Evaluation");
    }
  }


  // Call this function when your app starts
  // One Signal settings
  OneSignalInit(): void {
    // Uncomment to set OneSignal device logging to VERBOSE  
    // OneSignal.setLogLevel(6, 0);
    console.log("ONESIGNAL");
    // NOTE: Update the setAppId value below with your OneSignal AppId.
    OneSignal.setAppId("d6cca49b-53ab-4635-a90e-a770a6c58897");
    OneSignal.setNotificationOpenedHandler(function(jsonData) {
        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    });

    // Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
    OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
        console.log("User accepted notifications: " + accepted);
    });
  }
  
  OnVideoCallRequest() {
    this.socketIOService
        .OnVideoCallRequest()
        .subscribe(data => {
            this.callingInfo.name = data.fromname;
            this.callingInfo.content = "Calling....";
            this.callingInfo.type = "receiver";
            this.isVideoCall = true;
            this.callRequestIncoming(this.callingInfo.name);
        });
  }

  OnVideoCallRejected() {
    this.socketIOService
        .OnVideoCallRejected()
        .subscribe(data => {
            // Close alert
            //this.clearAlert();
            this.alertService.getAlert().dismiss();
            //this.alertService.dismiss();
            this.callingInfo.content = "Call Rejected ..";
            setTimeout(() => {
                this.Close();
            }, 1000);
        });
}

  OnVideoCallAccepted() {
    this.socketIOService
        .OnVideoCallAccepted()
        .subscribe(data => {
            // Close alert
            //this.clearAlert();
            this.alertService.getAlert().dismiss();
           // this.alertService.dismiss();
            var calee = this.user.find(a => a.username == data.fromname);
            this.userType = "dialer";
            this.caller = calee.id;
            this.isVideoCallAccepted = true;
            this.socketIOService.BusyNow();
            // Open video call on accept
            this.openVideoCallModal(this.userId , calee.username);
            this.Close();
        });
  }

  clearAlert(): void {
    this.currentAlert.dismiss(); 
  }

  GetLiveUsers() {
    this.socketIOService
        .GetConnectedUsers()
        .subscribe(data => {
             var users = data.filter(a => a.username != this.userId);;
             this.user = users;
             this.socketIOService.setActiveUsers(users);
             this.liveUserList = users;
             this.socketIOService.connectedusers = users;
             this.GetBusyUsers();
            // var count = 0;
            // for (var i in users) {
            //     if (this.liveUserList.indexOf(data[i]) === -1) {
            //         count++;
            //     }
            // }
            // if (count != this.liveUserList.length) {
            //     this.liveUserList = users;
            //     this.socketIOService.connectedusers = users;
            //     this.GetBusyUsers();
            // }
        });
  }

  GetBusyUsers() {
    this.socketIOService
        .GetBusyUsers()
        .subscribe(data => {
            this.liveUserList.forEach(a => { a.busy = false; });
            data.forEach(a => {
                var usr = this.liveUserList.find(b => b.username == a.username);
                if (usr) {
                    usr.busy = true;
                }
            });
        })
  }

  AddUser(username) {
    this.socketIOService.SetUserName(username)
        .subscribe(data => {
            if (data.username) {
                //user added
            }
        })
  }

  // Alert for incoming call
  async callRequestIncoming(name) {
    this.currentAlert = await this.alertController.create({
      header: 'Eingehender Anruf',
      subHeader: name,
      cssClass: 'custom-alert',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Ablehnen',
          cssClass: 'alert-button-cancel',
          handler: () => {
            this.RejectVideoCall();
          }
        },
        {
          text: 'Annehmen',
          cssClass: 'alert-button-confirm',
          handler: () => {
            this.AcceptVideoCall();
          }
        },
      ],
    });
    this.alertService.setAlert(this.currentAlert);
    await this.currentAlert.present();
  }

  RejectVideoCall() {
    var calee = this.user[0];
    if (calee) {
        this.socketIOService.VideoCallRejected(this.userId, calee.id);
        this.isVideoCallAccepted = false;
    }
    this.Close();
  }

  AcceptVideoCall() {
    var calee = this.user[0];
    if (calee) {

      console.log(this.userId)
      console.log(calee)
        this.socketIOService.VideoCallAccepted(this.userId, calee.id);
        this.userType = "receiver";
        this.caller = calee.id;
        this.isVideoCallAccepted = true;
        this.socketIOService.BusyNow();
        // Open video call on accept
        this.openVideoCallModal(this.userId , calee.username);
    }
    this.Close();
  }

  // Open video call modal
  async openVideoCallModal(craftsmanId, diyId) {
      const modal = await this.modalController.create({
        component: VideoCallComponent,
        cssClass: 'modal-fullscreen',
        componentProps: { 
          'userType' : this.userType,
          'caller' : this.caller,
          "craftsmanId": craftsmanId,
          "diyId": diyId
        }
      });
  
      modal.present();

      const { data } = await modal.onWillDismiss();
      if (data.status == 'ended') {
        this.CallBack(data);

      }
  }

  CallBack(event) {
    // open call summary both user after call end
    this.callSummary();
    this.isChat = false;
    this.isVideoCall = false;
    this.isAudioCall = false;
    this.isVideoCallAccepted = false;
    this.changeDetector.detectChanges();
    //location.reload();
}

  Close() {
    this.isVideoCall = false;
    this.changeDetector.detectChanges();
  }


  logOut() {
    this.socketIOService.RemoveUser();
    this.authService.logout();
    //location.reload();
  }

  navigateToProfile() {
    this.router.navigate(['/profile/' + this.userId]);
  }

  navigateToProfileSettings() {
    this.router.navigate(['/profile', 'edit']);
  }

  navigateToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  navigateTocraftman() {
    this.router.navigate(['/craftsman']);
  }

  // set introductionstatus false
  startIntroduction() {
    this.router.navigateByUrl('/introduction', { replaceUrl:true });
    this.authService.setIntroductionStatus(false)
  }

  updateCraftsmanStatus($event) {
    this.isAvailable = !this.isAvailable;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    this.authListenerSubs.unsubscribe();
  }
}
