import { Component } from '@angular/core';;
import { IonModal } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-call-summary',
  templateUrl: 'call-summary.component.html',
  styleUrls: ['./call-summary.component.scss'],
})
export class CallSummaryComponent {
  constructor(
    private modalCtrl: ModalController,
      ) {}


  ngOnInit() {

  }

  closeSummary(){
    return this.modalCtrl.dismiss();
  }
}