import { IonicModule } from '@ionic/angular';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ResetPasswordComponent } from './reset-password.component'
import { ResetPasswordRoutingModule } from './reset-password-routing.module';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ResetPasswordRoutingModule
  ],
  declarations: [ResetPasswordComponent],
  exports: [ResetPasswordComponent],
  entryComponents: [
    ResetPasswordComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ]
})
export class ResetPasswordModule {}
