import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
  private alert;

  constructor(private alertCtrl: AlertController) {

  }

  setAlert(alert) {
    this.alert = alert;
  }

  getAlert() {
    return this.alert;
  }

}