import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from "./auth/auth-interceptor";
import { NoCacheHeadersInterceptor } from "./auth/no-cache-headers-interceptor";
import { CallFormModule } from "./helpers/call-form/call-form.module";
import { ResetPasswordModule } from "./pages/reset-password/reset-password.module";
import { CallEvaluationModule } from './pages/call-evaluation/call-evaluation.module'

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule, 
    IonicModule.forRoot({ mode: 'ios' }), 
    AppRoutingModule, 
    BrowserAnimationsModule, 
    HttpClientModule,
    CallFormModule,
    ResetPasswordModule,
    CallEvaluationModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {}
