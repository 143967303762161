import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Storage } from '@capacitor/storage';
import { AuthService } from '../auth/auth.service';

export const INTRO_KEY = 'intro-seen';
 
@Injectable({
  providedIn: 'root'
})
export class IntroductionGuard implements CanLoad {
 
  constructor(
    private router: Router,
    private authService: AuthService,
    ) { }
 
  //check introduction status in localstorage
  async canLoad(): Promise<boolean> {
      const hasSeenIntro = await Storage.get({key: INTRO_KEY});      
      if (hasSeenIntro && (hasSeenIntro.value === 'true')) {
        this.authService.setIntroductionStatus(true);
        return true;
      } else {
        this.router.navigateByUrl('/introduction', { replaceUrl:true });
        this.authService.setIntroductionStatus(false);
        return false;
      }
  }
}