export const environment = {
  production: true,
  ssl: true,
  restServerPort: 443,
  restServer: 'hwa-api.vsupport68.de',
  isClickDummy: true,
  socketServer: 'https://hwa-api.vsupport68.de',
  iceServers: [{
      urls: [ "stun:fr-turn1.xirsys.com" ]
  }, {
      username: "nqy3xoG4PuxHxDX9_-GGIhGK_T3hTnVJIJly_pQMk7ejK-Z_kyRfMcTH_CM9i0ZEAAAAAGMkcJhkaWdpc2FwaWVucw==",
      credential: "da81fe0e-35bd-11ed-b0bc-0242ac120004",
      urls: [
          "turn:fr-turn1.xirsys.com:80?transport=udp",
          "turn:fr-turn1.xirsys.com:3478?transport=udp",
          "turn:fr-turn1.xirsys.com:80?transport=tcp",
          "turn:fr-turn1.xirsys.com:3478?transport=tcp",
          "turns:fr-turn1.xirsys.com:443?transport=tcp",
          "turns:fr-turn1.xirsys.com:5349?transport=tcp"
      ]
  }]
};
