import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl, NgForm, FormGroupDirective, ValidatorFn, AbstractControl, ValidationErrors  } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { Router, ActivatedRoute } from "@angular/router";
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  rForm: FormGroup;
  resetToken;
  matcher = new MyErrorStateMatcher();
constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.resetToken = this.route.snapshot.params['resetToken'];
    this.rForm = this._formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['']
    }, { validators: this.checkPassword })
  }

  checkPassword: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value
    return pass === confirmPass ? null : { notSame: true }
  }

  resetPassword(form: FormGroupDirective) {
    if (this.rForm.invalid) {
      this.authService.showToastOnError("Passwörter stimmen nicht überein")
      return;
    }
    this.authService.resetPassword(this.resetToken ,form.value.password);
    this.authService.showToastOnSuccess("Passwort wurde erfolgreich zurückgesetzt")
  }

}
