import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

var n = <any>navigator;

@Injectable({
  providedIn: 'root'
})
export class WebrtcService {
  public videoDevices = [];
  public audioDevices = [];

  constructor(
    private router: Router
  ) {
  }

  async initializeDevices() {
    if (!n.mediaDevices?.enumerateDevices) {
        console.log("enumerateDevices() not supported.");
    } else {
        // List cameras and microphones.
        await n.mediaDevices.enumerateDevices()
          .then((devices) => {
            // Clear attay first
            this.audioDevices.length = 0;
            this.videoDevices.length = 0;
            devices.forEach((device) => {
                if (device.kind == 'audioinput') {
                    this.audioDevices.push(device.deviceId);
                } else if (device.kind == 'videoinput') {
                    this.videoDevices.push(device.deviceId);
                }
                //console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
            });
            console.log(this.videoDevices)
          })
          .catch((err) => {
            console.error(`${err.name}: ${err.message}`);
          });
    }
  }

  getVideoDevices() {
    return this.videoDevices;
  }

  getAudioDevices() {
    return this.audioDevices;
  }
}